export const environments = {
  production: false,
  api: 'https://api-dev.guardadigital.com.br',
  translate: 'https://api-dev.guardadigital.com.br/v1/Translate/GetLanguagesTransform?Language=',
  viacepApi: 'https://viacep.com.br/ws/',
  serproApi: 'https://receitaws.com.br/v1',
  COOKIE_EXPIRE_TIME: 99999,
  COOKIE_PATH: '/',
  securityKey: '9Ta1YMPfYAgqlQUzN/qon02rNqT0SG/0',
  activetLinkUrl: 'https://www-dev.guardadigital.com.br/subscription/checkout',
  azureAdB2B: {
    tenantId: "5d262a4-6c83-42b1-9e05-b9049ae7de99",
    clientId: "1103cb4f-61b4-4730-8433-a64f3332bbd4",
    scopes: ["https://api.guardadigital.com.br/partner", "https://api.guardadigital.com.br/plan"],
    Instance: "https://login.microsoftonline.com/",
    auth: "https://login.microsoftonline.com/15d262a4-6c83-42b1-9e05-b9049ae7de99/oauth2/v2.0/authorize",
    knowAuth: ["https://login.microsoftonline.com"],
    redirectUri: 'https://backoffice-dev.guardadigital.com.br/'
    // redirectUri: 'https://localhost:4200/'
  }
}