{
  "name": "guarda-digital-backoffice",
  "version": "1.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --ssl",
    "start-prod": "ng serve --ssl --configuration=production -o",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.1.0",
    "@angular/common": "^17.1.0",
    "@angular/compiler": "^17.1.0",
    "@angular/core": "^17.1.0",
    "@angular/forms": "^17.1.0",
    "@angular/platform-browser": "^17.1.0",
    "@angular/platform-browser-dynamic": "^17.1.0",
    "@angular/router": "^17.1.0",
    "@azure/msal-angular": "^3.0.13",
    "@azure/msal-browser": "^3.10.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "crypto-js": "^4.2.0",
    "ng-antd-color-picker": "^1.1.0",
    "ng-zorro-antd": "^17.2.0",
    "ngx-mask": "^14.3.3",
    "remixicon": "^4.2.0",
    "rxjs": "~7.8.0",
    "simplebar-angular": "^3.2.4",
    "sweetalert2": "^11.10.5",
    "tslib": "^2.3.0",
    "uuidv4": "^6.2.13",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.1.1",
    "@angular/cli": "^17.1.1",
    "@angular/compiler-cli": "^17.1.0",
    "@types/crypto-js": "^4.2.2",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.3.2"
  }
}
