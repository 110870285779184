import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap, take } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { SilentRequest } from '@azure/msal-browser';
import { environments } from '../../environments/environments';

@Injectable({
  providedIn: 'root'
})
export class HttpRequesterService {
  private silent: SilentRequest = {
    scopes: environments.azureAdB2B.scopes
  };

  constructor(private http: HttpClient, private msalService: MsalService) { }

  get(url: string): Observable<any> {
    return this.msalService.acquireTokenSilent(this.silent)
      .pipe(switchMap((silentToken: any) => {
        return this.http.get<any>(url, {
          headers: new HttpHeaders({
            Authorization: `${silentToken.tokenType} ${silentToken.accessToken}`
          })
        })
        .pipe(take(1));
      }));
  }

  post(url: string, params: any): Observable<any> {
    return this.msalService.acquireTokenSilent(this.silent)
      .pipe(switchMap((silentToken: any) => {
        return this.http.post<any>(url, params, {
          headers: new HttpHeaders({
            Authorization: `${silentToken.tokenType} ${silentToken.accessToken}`
          })
        })
        .pipe(take(1));
      }));
  }

  patch(url: string, params: any): Observable<any> {
    return this.msalService.acquireTokenSilent(this.silent)
      .pipe(switchMap((silentToken: any) => {
        return this.http.patch<any>(url, params, {
          headers: new HttpHeaders({
            Authorization: `${silentToken.tokenType} ${silentToken.accessToken}`
          })
        })
        .pipe(take(1));
      }));
  }

  put(url: string, params: any): Observable<any> {
    return this.msalService.acquireTokenSilent(this.silent)
      .pipe(switchMap((silentToken: any) => {
        return this.http.put<any>(url, params, {
          headers: new HttpHeaders({
            Authorization: `${silentToken.tokenType} ${silentToken.accessToken}`
          })
        })
        .pipe(take(1));
      }));
  }

  delete(url: string): Observable<any> {
    return this.msalService.acquireTokenSilent(this.silent)
      .pipe(switchMap((silentToken: any) => {
        return this.http.delete<any>(url, {
          headers: new HttpHeaders({
            Authorization: `${silentToken.tokenType} ${silentToken.accessToken}`
          })
        })
        .pipe(take(1));
      }));
  }
}
