import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { SilentRequest } from '@azure/msal-browser';
import { Observable, catchError, map, of, switchMap, throwError } from 'rxjs';
import { environments } from '../../environments/environments';
import { GuardaDigitalLoaderService } from '../core/components/guarda-digital-loader/guarda-digital-loader.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  private silent: SilentRequest = {
    scopes: environments.azureAdB2B.scopes
  };

  constructor(private msalService: MsalService, private loaderService: GuardaDigitalLoaderService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status) {
          if (error.status === 404 || error.status === 401 || error.status === 0) {
            this.msalService.instance.logout();
          }
        } else {
          this.msalService.instance.logout();
        }

        this.loaderService.hide();

        return throwError(error);
      }));

  }
}
