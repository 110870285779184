import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { GuardaDigitalLoaderModule } from './core/components/guarda-digital-loader/guarda-digital-loader.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonService } from './core/services/common.service';
import { NZ_I18N, pt_BR } from 'ng-zorro-antd/i18n';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfigB2b, protectedResources } from '../assets/configs/auth-config';
import { MsalGuardConfiguration, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';

import pt from '@angular/common/locales/pt';

import { HttpRequesterService } from './services/http-requester.service';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { environments } from '../environments/environments';

registerLocaleData(pt);


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfigB2b);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    GuardaDigitalLoaderModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MsalModule.forRoot(
      new PublicClientApplication(msalConfigB2b),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: protectedResources.guardaDigital.scopes
        }
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [`${protectedResources.guardaDigital.endpoint}`, protectedResources.guardaDigital.scopes]
        ])
      }),
  ],
  providers: [
    CommonService,
    HttpRequesterService,
    {
      provide: NZ_I18N,
      useValue: pt_BR
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, environments.translate, '');
}
