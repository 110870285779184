import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GuardaDigitalLoaderService {
  loading: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  hide() {
    document.querySelector('body')?.classList.remove('avoid-scroll');
    this.loading.emit(false);
  }

  show() {
    document.querySelector('body')?.classList.add('avoid-scroll');
    this.loading.emit(true);
  }
}
