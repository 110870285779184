import { Component, Inject, OnInit } from '@angular/core';
import { GuardaDigitalLoaderService } from './core/components/guarda-digital-loader/guarda-digital-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { environments } from '../environments/environments';
import { Subject } from 'rxjs';
import { CommonService } from './core/services/common.service';
import { HttpErrorResponse } from '@angular/common/http';

const { version: version } = require('./../../package.json');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  sysVersion: string = version;
  isLoading: boolean = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private commonService: CommonService,
    private authService: MsalService,
    private loaderService: GuardaDigitalLoaderService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.loaderService.loading.subscribe((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
    
    this.translate.setDefaultLang('pt_BR');

    this.msalInitializar();
  }

  msalInitializar() {
    this.authService.handleRedirectObservable()
      .subscribe({
        next: (result: any) => {
          const accounts = this.authService.instance.getAllAccounts();

          if (accounts.length > 0) {
            this.checkAndSetActiveAccount();
          } else {
            this.authService.instance.loginRedirect();
          }
        },
        error: (error: HttpErrorResponse) => {
          console.log(error)
        }
      });
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);

      this.commonService.encrypt(accounts[0], 'UsAcDt')
    } else if (!activeAccount) {
      this.logout();
    } else {
      this.commonService.encrypt(activeAccount, 'UsAcDt')
    }
  }

  logout() {
    this.authService.instance.disableAccountStorageEvents();
    this.authService.instance.setActiveAccount(null);
    this.authService.instance.clearCache();
    localStorage.clear();

    this.authService.instance.logoutRedirect({
      postLogoutRedirectUri: environments.azureAdB2B.redirectUri
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
